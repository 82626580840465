import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const SemA2425Header: React.FC = () => {
    return (
        <Box px="1rem" bgcolor="#023e8a" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h3" color="white">
                Semester A 24/25
            </Typography>
        </Box>
    );
};

export default function SemA2425() {

    return (
        <Box pb="1rem">
            <SemA2425Header />
            <Box padding="0.5rem" display="flex" justifyContent="center">
            </Box>
            <Box display="flex" justifyContent="center" py="3rem">
                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="flex-start">
                    <Typography variant="h4" align="left" sx={{ fontWeight: '500' }}>
                        Stay tune, will update you all soon! 👾
                    </Typography>
                    <Typography variant="h5" align="left" sx={{ fontWeight: '300' }}>
                        <Link to={"/"} >Pre-joining</Link> our club so we can email you when activity/submission for this sem is ready!
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}