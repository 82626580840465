import { Box, Button, Container, Grid2, Paper, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getPreJoinStat, join, leave, PreJoinStat, isJoined } from "../../api/preJoin";
import { AuthState, signIn, signOut, useAuthState, useUserState } from "../../Firebase";
import { CheckCircle } from "@mui/icons-material";

const StyledPaper = styled(Paper)`
  padding: 16px;
  text-align: center;
`;
export default function PreJoin() {
    const [authState, ] = useAuthState();
    const user = useUserState();

    const [preJoinStat, setPreJoinStat] = useState<PreJoinStat | null>(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getPreJoinStat();
                setPreJoinStat(data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchData();
    }, []);

    const [isAlreadyJoined, setIsAlreadyJoined] = useState<boolean | null>(null);
    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                try {
                    const data = await isJoined();
                    setIsAlreadyJoined(data);
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };
        fetchData();
    }, [user]);

    const handlePreJoin = async () => {
        try {
            await join();
            const data = await isJoined();
            setIsAlreadyJoined(data);
            const statData = await getPreJoinStat();
            setPreJoinStat(statData);
        } catch (error) {
            console.error('Error joining:', error);
        }
    }

    const handlePreJoinLeave = async () => {
        try {
            await leave();
            const data = await isJoined();
            setIsAlreadyJoined(data);
            const statData = await getPreJoinStat();
            setPreJoinStat(statData);
        } catch (error) {
            console.error('Error joining:', error);
        }
    }

    const handleLoginAndPreJoin = async () => {
        try {
            await signIn();
            await join();
            const data = await isJoined();
            setIsAlreadyJoined(data);
            const statData = await getPreJoinStat();
            setPreJoinStat(statData);
        } catch (error) {
            console.error('Error joining:', error);
        }
    }

    return (
        <Box>
            <Container maxWidth="md">
                <Typography variant="body1" paddingTop="1rem">
                We are currently gathering interested students to officially register as a university club. <strong>By pre-joining, you&apos;ll be the first to know once our registration is approved and the next steps.</strong>
                </Typography>
                <Typography variant="body1" paddingTop="1rem">
                Additionally, your interest will help us to back up our club proposal with proof of student demand.
                </Typography>
            </Container>

            {
                authState !== AuthState.SIGNED_OUT && authState !== AuthState.GUEST_SIGNED_IN && isAlreadyJoined && (
                    <Box justifyContent="center" display="flex" paddingTop="1rem" flexDirection="column" alignItems="center">
                        <CheckCircle sx={{ color: 'green' }} fontSize="large"/>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }} color="green" paddingTop="0.25rem">
                            {authState === AuthState.CITYU_STAFF_SIGNED_IN ? 
                                'Thanks for supporting our club!' : 
                                'You have pre-joined this club!'
                            }
                        </Typography>
                    </Box>
                )
            }

            {authState === AuthState.GUEST_SIGNED_IN &&
                (
                    <>
                        <Box justifyContent="center" display="flex" paddingTop="1rem">
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                You are not using CityU account!, please log-out and log-in with CityU account to Pre-Join.
                            </Typography>
                        </Box>
                        <Box justifyContent="center" display="flex" paddingTop="1rem">
                            <Button variant="contained" color="error" onClick={signOut}>
                                Log-out
                            </Button>
                        </Box>
                    </>
                )
            }
            {
                authState === AuthState.SIGNED_OUT ? (
                    <Box justifyContent="center" display="flex" paddingTop="1rem">
                        <Button variant="contained" color="primary" onClick={handleLoginAndPreJoin}>
                            Log-In with CityU and Pre-Join
                        </Button>
                    </Box>
                ) : (
                    isAlreadyJoined !== null &&
                    (authState !== AuthState.GUEST_SIGNED_IN) && (
                        <Box justifyContent="center" display="flex" paddingTop="1rem">
                            <Button
                                variant="contained"
                                color={isAlreadyJoined ? "secondary" : "primary"}
                                onClick={isAlreadyJoined ? handlePreJoinLeave : handlePreJoin}
                            >
                                {isAlreadyJoined
                                    ? (authState === AuthState.CITYU_STAFF_SIGNED_IN ? 'Pull out my Support' : 'Pull out my Pre-Join')
                                    : (authState === AuthState.CITYU_STAFF_SIGNED_IN ? 'I love to see this club' : 'Pre-Join')
                                }
                            </Button>
                        </Box>
                    )
                )
            }
            
            <Box sx={{ flexGrow: 1 }}>
                <Grid2 container spacing={2} paddingTop={"1rem"}>
                    <Grid2 size={{ xs: 12, sm: 6 }}>
                        <StyledPaper variant="outlined" elevation={0}>
                            <Typography variant="h4">
                                {preJoinStat ? preJoinStat.cityuStudent : "-"}
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: '500' }}>
                            CityU {preJoinStat?.cityuStudent ?? 0 > 1 ? "Students" : "Student"} 🧑‍🎓
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
                                Pre-Joined
                            </Typography>
                        </StyledPaper>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6 }}>
                        <StyledPaper variant="outlined" elevation={0}>
                            <Typography variant="h4">
                                {preJoinStat ? preJoinStat.cityuStaff : "-"}
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: '500' }}>
                            CityU {preJoinStat?.cityuStaff ?? 0 > 1 ? "Staffs" : "Staff"} 🧑‍🏫 
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
                                Love to see this club
                            </Typography>
                        </StyledPaper>
                    </Grid2>
                </Grid2>
            </Box>

            


        </Box>
    )
}