import { Box, Button, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from 'react-router-dom';

export default function PageNotFound404() {
    const navigate = useNavigate();

    const handleGoBackHome = useCallback(() => {
        navigate('/');
    },[navigate]);
    
    return (
        <Box paddingTop="5rem">
            <Typography variant="h1" align="center" fontWeight="bold">
                404
            </Typography>
            <Typography variant="h3" align="center">
                Page Not Found
            </Typography>
            <Button variant="contained" color="info" sx={{ display: 'block', margin: 'auto', marginTop: '2rem' }} onClick={handleGoBackHome}>
                Back To Home
            </Button>
        </Box>
    );
}