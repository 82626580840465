import { Container, Typography, Box, Button } from "@mui/material";
import React from "react";

export default function ForCityUStaff() {

    return (
        <Container maxWidth="md">
            <Typography variant="body1" paddingTop="1rem">
                Have old CityU webpages that need renovation?
            </Typography>
            <Typography variant="body1" paddingTop="1rem">
                <strong>Propose your webpage to us</strong>, and we might make it our next club project!
            </Typography>
            <Box pt="1rem" display="flex" justifyContent="flex-end">
                <Button onClick={(e) => {
                    window.location.href = "mailto:mhiruntee2-c@my.cityu.edu.hk";
                    e.preventDefault();
                }}>
                    Email the Club
                </Button>
            </Box>
        </Container>
    )
}