import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Container } from "@mui/material";
import React from "react";

export default function QnA() {

    const questionAndAnswers = [
        {
            question: "I don't know how to code, can I join?",
            answer: "Yes, you can contribute as a designer or reviewer. Passion for web design is all you need. ❤️‍🔥",
        },
        {
            question: "I only know basic HTML and CSS, can I join?",
            answer: "Yes, our focus is on static, informative websites. Basic HTML and CSS are sufficient.",
        },
        {
            question: "Can I use frameworks like React or Flutter?",
            answer: "No 🤓, we simulate creating static websites with limited server resources. Stick to basic HTML, CSS, and JavaScript.",
        },
        {
            question: "What is a static website?",
            answer: "A static website consists of fixed content and is delivered to users exactly as stored. Files should be structured with index.html as the entry point.",
        },
        {
            question: "I don't know Figma, XD, or Sketch, can I still design?",
            answer: "Yes, you can also submit your web design in a PDF format. 💀",
        },
        {
            question: "Do I need to submit a design to code?",
            answer: "No, you can code based on others’ designs or create something entirely new without a predefined design.",
        },
        {
            question: "Do I need a group?",
            answer: "You can choose to work alone or as part of a group for both designing and coding. Submission can be individual or group-based.",
        },
        {
            question: "How can I find a group?",
            answer: "We have a forum similar to Canvas for club members to meet and connect.",
        },
        {
            question: "Is this club in-person?",
            answer: "Our activities are primarily online, but we may have occasional in-person social sessions. 🍷",
        }
    ]
    return (
        <Container maxWidth="md" sx={{paddingTop: '1rem'}}>
            {questionAndAnswers.map((questionAndAnswer) => (
                <Accordion key={questionAndAnswer.question}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    >
                        <strong>Q:</strong> &nbsp; {questionAndAnswer.question}
                    </AccordionSummary>
                    <AccordionDetails>
                    <strong>A:</strong> &nbsp;{questionAndAnswer.answer}
                    </AccordionDetails>
                </Accordion>

            ))}
        </Container>
    )
}