import { fetchWithAuth } from "../../Firebase";

export interface PreJoinStat {
    cityuStaff: number,
    cityuStudent: number,
}

const getPreJoinStat = async (): Promise<PreJoinStat> => {
    try {
        const response = await fetchWithAuth('pre-join/stat');
        
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        const preJoinStat: PreJoinStat = {
            cityuStaff: data.cityuStaff || 0,
            cityuStudent: data.cityuStudent || 0,
        };

        return preJoinStat;
    } catch (error) {
        console.error('Failed to fetch user data:', error);
        throw error;
    }
}

const join = async (): Promise<void> => {
    try {
        const response = await fetchWithAuth('pre-join/join', {method: 'POST'});
        
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return;
    } catch (error) {
        console.error('Failed to fetch user data:', error);
        throw error;
    }
}

const leave = async (): Promise<void> => {
    try {
        const response = await fetchWithAuth('pre-join/leave', {method: 'POST'});
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return;
    } catch (error) {
        console.error('Failed to fetch user data:', error);
        throw error;
    }
}

const isJoined = async (): Promise<boolean> => {
    try {
        const response = await fetchWithAuth('pre-join/isJoined', {method: 'GET'});
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.text();
        return data === 'true';
    } catch (error) {
        console.error('Failed to fetch user data:', error);
        throw error;
    }
}

export {getPreJoinStat, join, leave, isJoined};