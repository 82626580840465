import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import RichTextEditor from "../../common/RichTextEditor";
import { Box, Button, TextField, Typography } from "@mui/material";
import { SerializedEditorState } from "lexical";
import { createForumPost } from "../../api/forum";
import { useNavigate } from "react-router-dom";

export default function ForumAsk() {
    const [text, setText] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [metaText, setMetaText] = useState<SerializedEditorState | null>();
    const navigate = useNavigate();

    const handleSetTitle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }, [setTitle]);

    const submit = useCallback(async () => {
        setIsSubmitting(true);
        try {
            await createForumPost({
                title: title,
                content: {
                    raw: JSON.stringify(metaText),
                    preview: text,
                }
            });
            navigate('/forum');
        } catch (error) {
            console.error('Submission failed:', error);
        } finally {
            setIsSubmitting(false);
        }
    }, [metaText, text, navigate, title]);

    const isSubmitDisable = useMemo(() => {
        return isSubmitting || text === '' || !metaText || title === '';
    }, [isSubmitting, text, metaText, title]);

    return (
        <Box display="flex" justifyContent="center" >
            <Box maxWidth='md' width="100%">
                <Box display="flex" flexDirection="column">

                    <Typography variant="h4" p="1rem">Ask a Question?</Typography>
                    <TextField
                        label="Title"
                        sx={{ backgroundColor: "white" }}
                        value={title}
                        onChange={handleSetTitle} />
                    {/* <RichTextEditor
                    initialPayload={`{ "root": { "children": [ { "children": [ { "detail": 0, "format": 0, "mode": "normal", "style": "", "text": "Hello World", "type": "text", "version": 1 } ], "direction": "ltr", "format": "", "indent": 0, "type": "paragraph", "version": 1, "textFormat": 0, "textStyle": "" } ], "direction": "ltr", "format": "", "indent": 0, "type": "root", "version": 1 } }`}
                    isDisplay
                />
                <br /> */}
                    <RichTextEditor
                        onRawTextChange={setText}
                        onPayloadChange={setMetaText}
                    />
                    {/* {text}
                <br />
                {metaText && JSON.stringify(metaText)} */}

                    <Box p="0.5rem" marginRight="0" marginLeft="auto">
                        <Button variant="contained" color="primary" onClick={submit} disabled={isSubmitDisable}>Submit</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}