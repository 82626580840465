/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Box, Container, Typography } from '@mui/material';
import './App.css';
import AppBar from './common/AppBar';
import Home from './pages/Home';
import { Route, Routes } from "react-router-dom";
import PageNotFound404 from "./pages/PageNotFound404";
import { createTheme, MantineProvider } from "@mantine/core";
import Forum from "./pages/Forum";
import Gallery from "./pages/Gallery";
import ForumAsk from "./pages/Forum/ForumAsk";
import ProtectedRoute from "./common/ProtectedRoute";
import ForumPost from "./pages/Forum/ForumPost";
import SemA2425 from "./pages/SemA2425";

function App() {
  const mantineTheme = createTheme({
    /** Put your mantine theme override here */
  });

  return (
    <MantineProvider theme={mantineTheme}>

      <Container maxWidth={false} disableGutters sx={{ bgcolor: "#fff2f8", minHeight: "100vh" }}>
        <AppBar />
        <Container sx={{ paddingBottom: "2rem" }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/semA2425" element={<SemA2425 />} />
            <Route path="/forum" element={<Forum />} >
              <Route path="post/:forumPostId" element={<ForumPost />} />
              <Route element={<ProtectedRoute />}>
                <Route path="ask" element={<ForumAsk />} />
              </Route>
            </Route>
            <Route path="/gallery" element={<Gallery />} />
            <Route path="*" element={<PageNotFound404 />} />
          </Routes>
        </Container>
      </Container>

    </MantineProvider>
  )
}

export default App;
