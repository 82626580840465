import { Box, Card, Container, Divider, Typography } from "@mui/material";
import React from "react";

const OrSeparator = () => {
    return (
      <Box display="flex" alignItems="center" paddingY="0.5rem">
        <Box flex="1">
          <Divider sx={{borderColor: "#881043"}}/>
        </Box>
        <Typography variant="body1" sx={{ mx: 2 }} fontWeight="bold">
          OR
        </Typography>
        <Box flex="1">
          <Divider sx={{borderColor: "#881043"}}/>
        </Box>
      </Box>
    );
  }
  
export default function MemberDuties() {
    return (
        <Container maxWidth="md">
            <Typography variant="body1" paddingTop="1rem">
                To remain a member for the following semester, <strong>you need to complete ONE OF THE FOLLOWING TASK:</strong>
            </Typography>
            <Box display="grid" justifyContent="center" pt="1rem">
                <Card sx={{ borderRadius: 5 , padding: "1rem", display: "flex" , justifyContent: "center", flexDirection:"column"}} variant="outlined">
                    <Typography variant="body2" fontWeight="lighter" marginRight="auto">
                        At Week 7 (After Midterm)
                    </Typography>
                    <Box display="flex" justifyContent="center" gap="0.5rem" py="0.5rem">
                        <img src="https://s3-alpha.figma.com/hub/file/1481185752/fa4cd070-6a79-4e1b-b079-8b9b76408595-cover.png" height="30"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Adobe_XD_CC_icon.svg/800px-Adobe_XD_CC_icon.svg.png" height="30"/>
                        <img src="https://static-00.iconduck.com/assets.00/sketch-icon-256x256-nv90yymo.png" height="30"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Canva_icon_2021.svg/2048px-Canva_icon_2021.svg.png" height="30"/>
                    </Box>
                    <Typography variant="subtitle1" fontWeight="bold" marginLeft="auto" marginRight="auto">
                        Submit a web design
                    </Typography>
                    <Typography variant="subtitle2" marginLeft="auto" marginRight="auto">
                        (e.g. Figma, Adobe XD, Sketch, Canva etc.)
                    </Typography>
                    <Typography variant="body2" fontWeight="nornal" marginLeft="auto">
                        approx. 6 hours workload
                    </Typography>
                </Card>
                <OrSeparator />
                <Card sx={{ borderRadius: 5 , padding: "1rem", display: "flex" , justifyContent: "center", flexDirection:"column"}} variant="outlined">
                    <Typography variant="body2" fontWeight="lighter" marginRight="auto">
                        At Week 14 (After Final)
                    </Typography>
                    <Box display="flex" justifyContent="center" gap="0.5rem" py="0.5rem">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/512px-HTML5_logo_and_wordmark.svg.png" height="30"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/d/d5/CSS3_logo_and_wordmark.svg" height="30"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png" height="30"/>
                    </Box>
                    <Typography variant="subtitle1" fontWeight="bold" marginLeft="auto" marginRight="auto">
                        Submit a working web prototype
                    </Typography>
                    <Typography variant="subtitle2" marginLeft="auto" marginRight="auto">
                        Static Web Prototype in a Zip File of HTML, CSS, JS
                    </Typography>
                    <Typography variant="body2" fontWeight="nornal" marginLeft="auto">
                        approx. 12 hours workload
                    </Typography>
                </Card>
                <OrSeparator />
                <Card sx={{ borderRadius: 5 , padding: "1rem", display: "flex" , justifyContent: "center", flexDirection:"column"}} variant="outlined">
                    <Typography variant="body2" fontWeight="lighter" marginRight="auto">
                        At Week 8 or 15 (After Midterm or Final)
                    </Typography>
                    <Box display="flex" justifyContent="center" gap="0.5rem" py="0.5rem">
                        <img src="https://cdn-icons-png.flaticon.com/512/1789/1789313.png" height="30"/>
                    </Box>
                    <Typography variant="subtitle1" fontWeight="bold" marginLeft="auto" marginRight="auto">
                        Participate in online peer-review tasks
                    </Typography>
                    <Typography variant="subtitle2" marginLeft="auto" marginRight="auto">
                        Write some comment on Designs or Prototypes.
                    </Typography>
                    <Typography variant="body2" fontWeight="nornal" marginLeft="auto">
                        approx. 3 hours workload
                    </Typography>
                </Card>
            </Box>
        </Container>
    )
}