import { fetchWithAuth } from "../../Firebase";
import { Timestamp } from 'firebase/firestore';
import { UserProfile } from "../users";

interface RichTextContent {
  raw?: string,
  preview?: string,
}

export interface ForumPostPreview {
  id?: string,
  post?: {
    title?: string,
    content?: RichTextContent,
    createdAt?: Timestamp,
    isRemoved?: boolean,
    updatedAt?: Timestamp,
    userProfile?: UserProfile,
    numberOfReplies?: number,
  }
}

export interface ForumPostReply {
  id: string,
  reply: {
    content: RichTextContent,
    createdAt: Timestamp,
    isRemoved: boolean,
    updatedAt: Timestamp,
    userProfile: UserProfile,
  }
}
export interface ForumPost {
  post?: {
    title?: string,
    content?: RichTextContent,
    createdAt?: Timestamp,
    isRemoved?: boolean,
    updatedAt?: Timestamp,
    userProfile?: UserProfile,
  },
  reply: ForumPostReply[]
}


const getForumList = async (): Promise<ForumPostPreview[]> => {
  try {
    const response = await fetchWithAuth('forum/list');

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Failed to fetch user data:', error);
    throw error;
  }
}

const createForumPost = async (postPayload: {
  title: string,
  content: RichTextContent,
}): Promise<void> => {
  try {
    const response = await fetchWithAuth('forum/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postPayload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Failed to create forum post:', error);
    throw error;
  }
};

const getForumPost = async (forumPostId: string): Promise<ForumPost> => {
  try {
    const response = await fetchWithAuth(`forum/post/${forumPostId}`);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Failed to fetch user data:', error);
    throw error;
  }
}

const createForumReply = async (forumPostId: string, replyContent: RichTextContent): Promise<void> => {
  try {
    const response = await fetchWithAuth(`forum/post/${forumPostId}/reply`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ content: replyContent }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Failed to create forum post:', error);
    throw error;
  }
};

export { getForumList, createForumPost, getForumPost, createForumReply };