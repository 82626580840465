import { Box, Container } from "@mui/material";
import { Timeline, Text } from '@mantine/core';
import { IconGitBranch, IconMessagePlus, IconMickey, IconRosetteDiscountCheck, IconCode } from '@tabler/icons-react';

import React from "react";

export default function SemA2425Timeline() {
    return (
        <Container maxWidth="md">
            <Box display="flex" justifyContent="center" pt="1.5rem">
                <Timeline active={0} bulletSize={36} lineWidth={3}>
                    <Timeline.Item bullet={<IconGitBranch size={20} />} title="Target website announced" lineVariant="dashed">
                        <Text c="dimmed" size="sm">The club will announce which internal CityU static webpage will be our semester project.</Text>
                        <Text size="xs" mt={4}>Weeks 2-3</Text>
                    </Timeline.Item>

                    <Timeline.Item bullet={<IconMickey size={20} />} title="UI design submission">
                        <Text c="dimmed" size="sm">Members submit their UI designs using tools like Figma, Adobe XD, Sketch, or Canva.</Text>
                        <Text size="xs" mt={4}>Week 7 (after Midterm)</Text>
                    </Timeline.Item>

                    <Timeline.Item title="Peer review of UI designs" bullet={<IconMessagePlus size={20} />} lineVariant="dashed">
                        <Text c="dimmed" size="sm">All submitted UI designs will be evaluated by fellow members for constructive feedback.</Text>
                        <Text size="xs" mt={4}>Week 8</Text>
                    </Timeline.Item>

                    <Timeline.Item title="Web prototype submission" bullet={<IconCode size={20} />}>
                        <Text c="dimmed" size="sm">Members submit their final static web prototypes in a zip file (HTML, CSS, JS).</Text>
                        <Text size="xs" mt={4}>Week 14 (after Final)</Text>
                    </Timeline.Item>

                    <Timeline.Item title="Peer review and voting on designs & prototypes" bullet={<IconRosetteDiscountCheck size={20} />}>
                        <Text c="dimmed" size="sm">Members review and vote on the best designs and coded prototypes to recognize top</Text>
                        <Text size="xs" mt={4}>Week 15</Text>
                    </Timeline.Item>
                </Timeline>
            </Box>
        </Container>
    )
}