import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthState, useAuthState } from "../Firebase";
import { Box, CircularProgress } from "@mui/material";

interface ProtectedRouteProps {
    children?: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = () => {
  const [authState, isLoading] = useAuthState();

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" p="5rem">
                <CircularProgress />
            </Box>
        );
    }

    if (authState === AuthState.SIGNED_OUT) {
        return <Navigate to="/" />;
    }

    return <Outlet />;
};

export default ProtectedRoute;