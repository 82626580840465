import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { AuthState, signIn, useAuthState } from "../../Firebase";
import { Add } from "@mui/icons-material";
import { useNavigate, useOutlet } from "react-router-dom";
import ForumList from "./ForumList";

export const ForumHeaderButton = () => {
    const [authState, ] = useAuthState();
    const navigate = useNavigate();
    const handleAsk = useCallback(() => {
        navigate('/forum/ask');
    },[navigate]);

    return (
        <Box>
            {
                authState === AuthState.SIGNED_OUT ? (
                    <Button variant="contained" onClick={() => signIn()}>
                        Sign in To ask question
                    </Button>
                ) : (
                    <Button variant="contained" sx={{ backgroundColor: "#88d96c" }} endIcon={<Add />} onClick={handleAsk}>
                        Ask Question
                    </Button>
                )
            }
        </Box>
    );
}

const ForumHeader: React.FC<{ showFormHeaderButton?: boolean }> = ({ showFormHeaderButton = true }) => {
    return (
        <Box px="1rem" bgcolor="#782849" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h3" color="white">
                Forum
            </Typography>
            {showFormHeaderButton && <ForumHeaderButton />}
        </Box>
    );
};

export default function Forum() {

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const outlet = useOutlet();

    const showFormHeaderButtonAtHeader = useMemo(()=> isLargeScreen,[]);

    return (
        <Box pb="1rem">
            <ForumHeader showFormHeaderButton={outlet === null && showFormHeaderButtonAtHeader} />
            {
                outlet || (
                    <ForumList showFormHeaderButton={outlet === null && !showFormHeaderButtonAtHeader}/>
                )
            }
        </Box>
    )
}

