import { fetchWithAuth } from "../../Firebase";
import { Timestamp } from 'firebase/firestore';

export interface UserData {
    displayName?: string,
    email?: string,
    photoURL?: string,
    role?: string,
    createdAt?: Timestamp,
    isAdmin?: boolean,
}

export type UserProfile = Pick<UserData, "displayName" | "email" | "photoURL">;

const getUserData = async (): Promise<UserData> => {
    try {
        const response = await fetchWithAuth('users/me');
        
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        const userData: UserData = {
            displayName: data.displayName,
            email: data.email,
            photoURL: data.photoURL,
            role: data.role,
            createdAt: data.createdAt
        };

        return userData;
    } catch (error) {
        console.error('Failed to fetch user data:', error);
        throw error;
    }
}

export {getUserData};