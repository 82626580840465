// Firebase.ts

import { initializeApp } from 'firebase/app';
import { getAuth, OAuthProvider, signInWithPopup, getIdToken, onAuthStateChanged, User, signOut as firebaseSignOut } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
// import { getAnalytics } from "firebase/analytics";
import { useEffect, useState } from 'react';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_CONFIG_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGESENDERID,
    appId: process.env.REACT_APP_FIREBASE_CONFIG_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENTID
};

const backendBaseUrl = "https://us-central1-web-maker-club.cloudfunctions.net/api";

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);

const signIn = async () => {
    const provider = new OAuthProvider('microsoft.com');
    try {
        await signInWithPopup(auth, provider);
    } catch (error) {
        console.error(error);
    }
}

const callProtectedFunction = async (input: RequestInfo, init?: RequestInit) => {
    try {
        const token = auth.currentUser == null ? null : await getIdToken(auth.currentUser);

        // Clone init object to avoid mutating the original
        const fetchInit = { ...init, headers: { ...(init?.headers || {}), 'Authorization': `Bearer ${token}` } };

        const response = await fetch(input, fetchInit);
        return response;
    } catch (error) {
        console.error("Error calling protected function:", error);
        throw error;
    }
}

export const enum AuthState {
    SIGNED_OUT = "SIGNED_OUT",
    CITYU_STAFF_SIGNED_IN = "CITYU_STAFF_SIGNED_IN",
    CITYU_STUDENT_SIGNED_IN = "CITYU_STUDENT_SIGNED_IN",
    GUEST_SIGNED_IN = "GUEST_SIGNED_IN"
}

const useAuthState = (): [AuthState, boolean] => {
    const determineAuthState = (user: User | null): AuthState => {
        if (!user) {
            return AuthState.SIGNED_OUT;
        } else if (user.email?.endsWith('@cityu.edu.hk')) {
            return AuthState.CITYU_STAFF_SIGNED_IN;
        } else if (user.email?.endsWith('@my.cityu.edu.hk')) {
            return AuthState.CITYU_STUDENT_SIGNED_IN;
        } else {
            return AuthState.GUEST_SIGNED_IN;
        }
    }
    const [authState, setAuthState] = useState<AuthState>(determineAuthState(auth.currentUser));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setAuthState(determineAuthState(user));
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    return [authState, loading];
}

const useUserState = (): User | null => {
    const [user, setUser] = useState<User | null>(auth.currentUser);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    return user;
}

const fetchWithAuth = async (endpoint: string, options?: RequestInit): Promise<Response> => {
    try {
        const token = auth.currentUser ? await getIdToken(auth.currentUser) : null;

        const fetchOptions = {
            ...options,
            headers: {
                ...options?.headers,
                'Authorization': token ? `Bearer ${token}` : ''
            }
        };

        const response = await fetch(`${backendBaseUrl}/${endpoint}`, fetchOptions);
        return response;
    } catch (error) {
        console.error("Error calling backend API:", error);
        throw error;
    }
}

const signOut = async () => {
    try {
        await firebaseSignOut(auth);
    } catch (error) {
        console.error(error);
    }
}

const firebaseTimestampToDate = (timestamp: Timestamp): Date => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const seconds = (timestamp as any)._seconds;
    return new Date(seconds * 1000);
}

export { auth, signIn, signOut, callProtectedFunction, useAuthState, useUserState, fetchWithAuth, firebaseTimestampToDate };