import { Container, Typography } from "@mui/material";
import React from "react";

export default function Objective() {
    return (
        <Container maxWidth="md">
            <Typography variant="body1" paddingTop="1rem">
            Our goal is to enhance students&apos; skills in designing and/or coding practical static websites. We&apos;ll work on projects to <strong>renovate/prototyping university web pages, giving them modern and informative designs</strong>.
            </Typography>
        </Container>
    )
}