import React from "react";
import { Box, Container, Divider, Typography } from "@mui/material";
import Motto from "./Motto";
import PreJoin from "./PreJoin";
import Objective from "./Objective";
import WhatDoesThisClubDo from "./WhatDoesThisClubDo";
import QnA from "./QnA";
import ForCityUStaff from "./ForCityUStaff";
import WhyBecomeAMember from "./WhyBecomeAMember";
import MemberDuties from "./MemberDuties";
import SemA2425Timeline from "./SemA2425Timeline";

interface SectionProps {
    children: React.ReactNode;
    sectionName: string;
    isSkipDivider?: boolean;
}

export const Section: React.FC<SectionProps> = (
    { children, sectionName, isSkipDivider = false }
) => (
    <>
        <Box py="3rem">
            <Typography variant="h5" sx={{ fontWeight: '400' }}>
                {sectionName}
            </Typography>
            {children}
        </Box>
        {!isSkipDivider && <Divider />}
    </>
);
export default function Home() {

    return (
        <Container maxWidth="md" sx={{ paddingBottom: "2rem" }}>
            <Box pt="5rem" pb="3rem">
                <Motto />
            </Box>
            <Section sectionName="Pre-Join Us! 🚀">
                <PreJoin />
            </Section>
            <Section sectionName="Objective 📍">
                <Objective />
            </Section>
            <Section sectionName="What Does This Club (will) Do? 🦾">
                <WhatDoesThisClubDo />
            </Section>
            <Section sectionName="Sem A 24/25 Timeline (Expected) 📅">
                <SemA2425Timeline />
            </Section>
            <Section sectionName="Member Duties 📚">
                <MemberDuties />
            </Section>
            <Section sectionName="Why Become a Member? 🔥">
                <WhyBecomeAMember />
            </Section>
            <Section sectionName="Q&A 🙋‍♀️">
                <QnA />
            </Section>
            <Section sectionName="For CityU Staff 👨‍🏫" isSkipDivider>
                <ForCityUStaff />
            </Section>
        </Container>

    )
}