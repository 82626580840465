import { Box, Card, CircularProgress, Divider, Grid2, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { ForumHeaderButton } from ".";
import { ForumPostPreview, getForumList } from "../../api/forum";
import { firebaseTimestampToDate } from "../../Firebase";
import { Link } from "react-router-dom";

export default function ForumList({ showFormHeaderButton = true }: { showFormHeaderButton?: boolean }) {
    const [forumPostPreviews, setForumPostPreviews] = useState<ForumPostPreview[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await getForumList();
            setForumPostPreviews(data);
        } catch (error) {
            console.error('Error fetching posts data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [setForumPostPreviews]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const textLimitStantisize = useCallback((text: string) => {
        const limit = 150
        const trimmedText = text.trim();
        if (trimmedText.length > limit) {
            return trimmedText.substring(0, limit) + "...";
        }
        return trimmedText;
    }, []);

    return (<>
        <Box padding="0.5rem" display="flex" justifyContent="center">
            {showFormHeaderButton && <ForumHeaderButton />}
        </Box>
        {
            isLoading ?
                <Box display="flex" justifyContent="center" alignItems="center" p="8rem">
                    <CircularProgress />
                </Box> :
                <Box>
                    <Grid2 container spacing={2}>
                        {
                            forumPostPreviews && forumPostPreviews.map((forumPostPreview) => (
                                <Grid2 key={forumPostPreview.id} size={12}>
                                    <Link to={`/forum/post/${forumPostPreview.id}`} style={{textDecoration: 'none'}}>
                                    <Card sx={{ padding: '1rem', backgroundColor: "#fffafc" }}>
                                        <Typography variant="h6">
                                            {forumPostPreview.post?.title}
                                        </Typography>
                                        <Typography variant="body1">
                                            {textLimitStantisize(forumPostPreview.post?.content?.preview ?? "")}
                                        </Typography>
                                        <Divider sx={{ padding: '0.5rem' }} />
                                        <Typography variant="subtitle2" align="right" fontWeight="normal">
                                            {forumPostPreview.post?.numberOfReplies ?? "0"} repiled
                                        </Typography>
                                        <Typography variant="subtitle2" align="right" fontWeight="normal">
                                            Post By {forumPostPreview.post?.userProfile?.displayName ?? "Unknown"} At {forumPostPreview.post?.createdAt ? firebaseTimestampToDate(forumPostPreview.post.createdAt)
                                                .toLocaleString('en-US', {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                }) : "Unknown"}
                                        </Typography>
                                    </Card>
                                    </Link>
                                </Grid2>
                            )
                            )
                        }
                    </Grid2>
                </Box>
        }
    </>);

}