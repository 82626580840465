import { Box, Container, Grid2 as Grid, Typography } from "@mui/material";
import { ReactComponent as TargetSvg } from '../../svg/undraw_target_re_fi8j.svg';
import { ReactComponent as DesignSvg } from '../../svg/undraw_design_team_re_gh2d.svg';
import { ReactComponent as PeerReviewSvg } from '../../svg/undraw_public_discussion_re_w9up.svg';
import { ReactComponent as CodingSvg } from '../../svg/undraw_programmer_re_owql.svg';
import { ReactComponent as FinalReviewSvg } from '../../svg/undraw_informed_decision_p2lh.svg';
import React from "react";

export default function WhatDoesThisClubDo() {
    return (
        <Container maxWidth="md">
            <Grid container spacing={2} paddingTop="1.5rem" justifyContent="center">
                <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                    <TargetSvg width={150} height={150} />
                    <Box padding="1rem">
                        <Box display="flex" alignItems="baseline">
                            <Typography variant="h5" paddingTop="1rem" fontWeight="lighter" paddingRight="0.5rem">
                                1
                            </Typography>
                            <Typography variant="h6" paddingTop="1rem">
                                Target Selection
                            </Typography>
                        </Box>
                        <Typography variant="body1">
                            Each semester, we&apos;ll select one university static page to renovate.
                        </Typography>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                    <DesignSvg width={150} height={150} />
                    <Box padding="1rem">
                        <Box display="flex" alignItems="baseline">
                            <Typography variant="h5" paddingTop="1rem" fontWeight="lighter" paddingRight="0.5rem">
                                2
                            </Typography>
                            <Typography variant="h6" paddingTop="1rem">
                                Design Submission
                            </Typography>
                        </Box>
                        <Typography variant="body1">
                            Club members submit their designs (using tools like Figma, XD, Sketch) either individually or in groups.
                        </Typography>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                    <PeerReviewSvg width={150} height={150} />
                    <Box padding="1rem">
                        <Box display="flex" alignItems="baseline">
                            <Typography variant="h5" paddingTop="1rem" fontWeight="lighter" paddingRight="0.5rem">
                                3
                            </Typography>
                            <Typography variant="h6" paddingTop="1rem">
                                Peer Review
                            </Typography>
                        </Box>
                        <Typography variant="body1">
                            Designs will undergo peer-reviews and constructive feedback from members.
                        </Typography>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                    <CodingSvg width={150} height={150} />
                    <Box padding="1rem">
                        <Box display="flex" alignItems="baseline">
                            <Typography variant="h5" paddingTop="1rem" fontWeight="lighter" paddingRight="0.5rem">
                                4
                            </Typography>
                            <Typography variant="h6" paddingTop="1rem">
                                Coding
                            </Typography>
                        </Box>
                        <Typography variant="body1">
                            Members will then submit the working static website as a zip file.
                        </Typography>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                    <FinalReviewSvg width={150} height={150} />
                    <Box padding="1rem">
                        <Box display="flex" alignItems="baseline">
                            <Typography variant="h5" paddingTop="1rem" fontWeight="lighter" paddingRight="0.5rem">
                                5
                            </Typography>
                            <Typography variant="h6" paddingTop="1rem">
                                Final Review
                            </Typography>
                        </Box>
                        <Typography variant="body1">
                            The static web prototypes will be peer-reviewed, and members will vote for the best design and prototype.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}