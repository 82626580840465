import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const GalleryHeader: React.FC = () => {
    return (
        <Box px="1rem" bgcolor="#ff006d" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h3" color="white">
                Gallery
            </Typography>
        </Box>
    );
};

export default function Gallery() {


    return (
        <Box pb="1rem">
            <GalleryHeader />
            <Box padding="0.5rem" display="flex" justifyContent="center">
            </Box>
            <Box display="flex" justifyContent="center" py="3rem">
                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="flex-start">
                    <Typography variant="h4" align="left" sx={{ fontWeight: '500' }}>
                        Help us to build this Gallery 🧩
                    </Typography>
                    <Typography variant="h5" align="left" sx={{ fontWeight: '300' }}>
                        starting by <Link to={"/"} >pre-joining</Link> our club!
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}