import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';

import React, { useMemo, useRef } from 'react';

import { useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, EditorState, SerializedEditorState } from 'lexical';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { RichTextEditorTheme } from './RichTextEditorTheme';

const defaultPlaceholder = 'Enter some text...';

const editorConfig: InitialConfigType = {
    namespace: 'React.js Demo',
    nodes: [],
    // Handling of errors during update
    onError(error: Error) {
        throw error;
    },
    // The editor theme
    theme: RichTextEditorTheme,
};

function MyOnChangePlugin({ onChange: onChange }: { onChange: (editorState: EditorState) => void }) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            onChange(editorState);
        });
    }, [editor, onChange]);
    return null;
}

function SetTextPlugin({ initialJson }: { initialJson: SerializedEditorState | null }) {
    const [editor] = useLexicalComposerContext();
    const isInitialized = useRef(false); // Track if the editor has initialized

    useEffect(() => {
        if (isInitialized.current || !initialJson) {
            return;
        }
        const newEditorState = editor.parseEditorState(initialJson);
        editor.setEditorState(newEditorState);
        isInitialized.current = true; // Mark as initialized after first run
    }, [editor, initialJson]);

    return null;
}
export default function RichTextEditor({ initialPayload, isDisplay = false, onPayloadChange, onRawTextChange, autoFocus = false, placeholder = defaultPlaceholder }:
    {
        initialPayload?: string,
        isDisplay?: boolean,
        onPayloadChange?: (payload: SerializedEditorState) => void,
        onRawTextChange?: (rawText: string) => void,
        autoFocus?: boolean
        placeholder?: string
    }) {
    const [editorState, setEditorState] = useState<EditorState | null>(null);
    function onChange(editorState: EditorState) {
        setEditorState(editorState);
    }

    useEffect(() => {
        if (editorState && onPayloadChange) {
            onPayloadChange(editorState.toJSON());
        }
    }, [editorState, onPayloadChange]);

    useEffect(() => {
        if (editorState && onRawTextChange) {
            onRawTextChange(editorState.read(() => $getRoot().getTextContent()));
        }
    }, [editorState, onRawTextChange]);

    const initialJson: SerializedEditorState | null = useMemo(() => {
        if (initialPayload) {
            try {
                return JSON.parse(initialPayload) as SerializedEditorState;
            } catch (error) {
                console.error("Failed to parse JSON:", error);
                return null;
            }
        }
        return null;
    }, [initialPayload]);


    return (
        <>
            <LexicalComposer initialConfig={{ ...editorConfig, editable: !isDisplay }}>
                <div className="editor-container">
                    {!isDisplay && <ToolbarPlugin />}
                    <div className="editor-inner">
                        <RichTextPlugin
                            contentEditable={
                                <ContentEditable
                                    className="editor-input"
                                    aria-placeholder={placeholder}
                                    placeholder={
                                        <div className="editor-placeholder">{placeholder}</div>
                                    }
                                    style={{ minHeight:  isDisplay ? undefined: '150px' }}
                                />
                            }
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        {autoFocus && <AutoFocusPlugin />}
                        <MyOnChangePlugin onChange={onChange} />
                        <SetTextPlugin initialJson={initialJson} />
                    </div>
                </div>
            </LexicalComposer>
            {/* <p>
                {
                    editorState && JSON.stringify(editorState.toJSON(), null, 2)
                }
            </p> */}
        </>
    );
}