import { AuthState, signIn, signOut, useAuthState, useUserState } from '../Firebase';
import { AppBar as AppBarMUI, Avatar, Box, Button, Container, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import { Groups3, Logout, Menu as MenuIcon } from '@mui/icons-material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getUserData, UserData } from '../api/users';
import { useNavigate } from 'react-router-dom';
export default function AppBar() {
    const [authState, ] = useAuthState();
    const navigate = useNavigate();
    const user = useUserState();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };

    const closeAndNavigate = useCallback((path: string) => {
        navigate(path);
        handleCloseNavMenu();
    }, [navigate]);

    const goHome = useCallback(() => {
        navigate('/');
    }, [navigate]);

    const pages = [
        { title: 'Home', path: '/' },
        { title: 'Sem:A/24-25', path: '/semA2425' },
        { title: 'Forum', path: '/forum' },
        { title: 'Gallery', path: '/gallery' },
    ];

    const title = "CityU Web Maker Club (Pending)";

    const [userData, setUserData] = useState<UserData | null>(null);
    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                try {
                    const data = await getUserData();
                    setUserData(data);
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };
        fetchData();
    }, [user]);

    const userRole = useMemo(() => {
        switch (userData?.role) {
            case 'cityu-staff':
                return 'CityU Staff';
            case 'cityu-student':
                return 'CityU Student';
            case 'club-member':
                return 'Club Member';
            case 'guest':
                return 'Guest';
            default:
                return null;
        }
    }, [userData]);

    const userAbbreviation = user?.displayName?.split(' ').map((name) => name[0]).join('');
    return (
        <Container maxWidth="lg">
            <Box sx={{ flexGrow: 1 }}>
                <AppBarMUI position="sticky" sx={{ bgcolor: "white", color: "#bf165e" }}>
                    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }} >

                        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: "center" }}>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={goHome}
                            >
                                <Groups3 />
                            </IconButton>
                            <Typography variant="h6" component="div" onClick={goHome}>
                                {title}
                            </Typography>
                            <Divider orientation='vertical' variant="middle" flexItem sx={{ paddingX: "0.5rem", borderColor: "#bf165e" }} />

                            <Box paddingLeft="0.5rem">
                                {pages.map((page) => (
                                    <Button
                                        key={page.title}
                                        onClick={() => closeAndNavigate(page.path)}
                                        sx={{ mx: 1, color: "#bf165e", margin: 0, minWidth: 0, fontWeight: 'normal' }}
                                    >
                                        {page.title}
                                    </Button>
                                ))}
                            </Box>
                        </Box>

                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{ display: { xs: 'block', md: 'none' } }}
                            >

                                {pages.map((page) => (

                                    < MenuItem key={page.title} onClick={() => closeAndNavigate(page.path)}>
                                        <Typography sx={{ textAlign: 'center' }}>{page.title}</Typography>
                                    </MenuItem>

                                ))}


                            </Menu>
                        </Box>

                        <Box display="flex" alignItems="center">
                            <Groups3 sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                            <Typography
                                variant="h6"
                                sx={{
                                    mr: 3,
                                    display: { xs: 'flex', md: 'none' },
                                }}
                                onClick={goHome}
                            >
                                {title}
                            </Typography>
                        </Box>

                        <Box>
                            {
                                (authState === AuthState.SIGNED_OUT) ?
                                    <Button color="inherit" onClick={signIn}>Login</Button>
                                    :
                                    <React.Fragment>
                                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                            <Tooltip title="Account settings">
                                                <IconButton
                                                    onClick={handleOpenUserMenu}
                                                    size="small"
                                                    sx={{ ml: 2 }}
                                                    aria-controls={open ? 'account-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                >
                                                    <Avatar sx={{ width: 32, height: 32 }}>{userAbbreviation}</Avatar>
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Menu
                                            anchorEl={anchorEl}
                                            id="account-menu"
                                            open={open}
                                            onClose={handleCloseUserMenu}
                                            onClick={handleCloseUserMenu}
                                            slotProps={{
                                                paper: {
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                        mt: 1.5,
                                                        '& .MuiAvatar-root': {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        '&::before': {
                                                            content: '""',
                                                            display: 'block',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 14,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: 'background.paper',
                                                            transform: 'translateY(-50%) rotate(45deg)',
                                                            zIndex: 0,
                                                        },
                                                    },
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <Box padding={2} display={'inline-block'}>
                                                <Typography>{user?.displayName}</Typography>
                                                {userRole &&
                                                    <Typography variant='subtitle2' style={{ float: 'right' }}>{userRole}</Typography>
                                                }
                                            </Box>
                                            <Divider />
                                            <MenuItem onClick={signOut}>
                                                <ListItemIcon>
                                                    <Logout fontSize="small" />
                                                </ListItemIcon>
                                                Logout
                                            </MenuItem>
                                        </Menu>
                                    </React.Fragment>
                            }
                        </Box>
                    </Toolbar>
                </AppBarMUI>
            </Box>
        </Container >
    );
}