import { Box, Typography } from "@mui/material";
import React from "react";
import TextTransition, { presets } from "react-text-transition";

export default function Motto() {
    const TEXTS = ['Make', 'Design', 'Run', 'Remaster', 'Renovate', 'Code', 'Fashion', 'Improve'];

    const [index, setIndex] = React.useState(0);
    React.useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);
    return (
        <Box
            display="grid"
            justifyContent="center"
            alignItems="center">
            <Typography variant="h3" sx={{ fontWeight: 'normal' }}>
                Join Us To
            </Typography>
            <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
                <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition>
            </Typography>
            <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
                A Website 🎨
            </Typography>
        </Box>
    )
}