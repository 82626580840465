import { Box, Card, Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import Slider, { Settings as SliderSettings } from "@ant-design/react-slick";

import React, { useMemo } from "react";

export default function WhyBecomeAMember() {

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

    const slidesToShow = useMemo(() => {
        if (isLargeScreen) {
            return 3;
        } else if (isMediumScreen) {
            return 2;
        } else {
            return 1;
        }
    }, [isLargeScreen, isMediumScreen]);

    const content = [
        {
            emoji: "🎤",
            title: "Showcase Your Talent",
            description: "Your designs and websites will be featured on our club's website with creator credits."
        },
        {
            emoji: "🍳",
            title: "Enhance Your Skills",
            description: "Gain experience in web development/design and peer-reviewing."
        },
        {
            emoji: "🏆",
            title: "Recognition",
            description: "Contributions will be archived and appreciated on our club website."
        }
    ]

    const sliderSettings: SliderSettings = useMemo(() => ({
        dots: slidesToShow < content.length,
        arrows: slidesToShow < content.length,
        infinite: true,
        speed: 3,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        adaptiveHeight: false,
    }), [slidesToShow]);

    return (
        <Container maxWidth="md">
            <Box py="1rem">
                <Slider {...sliderSettings}>
                    {
                        content.map((item) => (
                            <div key={item.title}>
                                <Card style={{ marginInline: '0.5rem' }}>
                                    <Box p="0.5rem">
                                        <Box padding="1rem" display="flex" alignItems="center">
                                            <Typography variant="h4" paddingRight="0.5rem">
                                                {item.emoji}
                                            </Typography>
                                            <Typography variant="h6">
                                                {item.title}
                                            </Typography>
                                        </Box>

                                        <Typography variant="body1" padding="0rem 1rem 1rem 1rem">
                                            {item.description}
                                        </Typography>
                                    </Box>
                                </Card>
                            </div>
                        ))
                    }
                </Slider>
            </Box>
        </Container>
    )
}