import { Box, Button, Divider, Grid2, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { createForumReply, ForumPostReply } from "../../api/forum";
import RichTextEditor from "../../common/RichTextEditor";
import { AuthState, firebaseTimestampToDate, signIn, useAuthState } from "../../Firebase";
import { SerializedEditorState } from "lexical";

interface ReplyListSectionProps {
    replies: ForumPostReply[];
    postId: string;
    reload: () => void;
}
const ReplyListSection: React.FC<ReplyListSectionProps> = ({ replies, postId, reload }) => {

    const numberOfReplies = replies.length;
    const [authState,] = useAuthState();

    return (
        <Box>
            <Typography variant="h5" paddingTop="1rem" fontWeight="500">
                {numberOfReplies > 1 ? "Replies" : "Reply"}
            </Typography>
            {
                numberOfReplies > 0 ? (
                    <Grid2 container spacing={2} py="1rem">
                        {
                            replies.map((reply) => (
                                <Box key={reply.id} px="0.5rem" width="100%">
                                    <RichTextEditor
                                        initialPayload={reply.reply.content.raw}
                                        isDisplay
                                    />
                                    <Box p="0.5rem">
                                        <Typography variant="subtitle2" align="right" fontWeight="normal">
                                            Reply By {reply.reply.userProfile.displayName ?? "Unknown"}
                                        </Typography>
                                        <Typography variant="subtitle2" align="right" fontWeight="normal">
                                            At {firebaseTimestampToDate(reply.reply.createdAt)
                                                .toLocaleString('en-US', {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))
                        }
                    </Grid2>
                ) : (
                    <Typography>There is no replies yet</Typography>
                )
            }
            <Box pt="1rem">

                <Divider />
                {
                    authState !== AuthState.SIGNED_OUT ?
                        <ReplySection postId={postId} reload={reload} />
                        : <Box display="flex" justifyContent="center" pt="0.5rem">
                            <Button variant="contained" onClick={() => signIn()}>
                                Sign in to reply
                            </ Button>
                        </ Box>
                }
            </Box>
        </Box>
    );
}

interface ReplySectionProps {
    postId: string;
    reload: () => void;
}
const ReplySection: React.FC<ReplySectionProps> = ({ postId, reload }) => {
    const [metaText, setMetaText] = useState<SerializedEditorState | null>();
    const [text, setText] = useState<string>('');

    const [isSubmitting, setIsSubmitting] = useState(false);

    const submit = useCallback(async () => {
        setIsSubmitting(true);
        try {
            await createForumReply(postId, {
                raw: JSON.stringify(metaText),
                preview: text,
            });
            reload();
        } catch (error) {
            console.error('Submission failed:', error);
        } finally {
            setIsSubmitting(false);
        }
    }, [metaText, text, postId, reload]);

    const isSubmitDisable = useMemo(() => {
        return isSubmitting || text === '' || !metaText;
    }, [isSubmitting, metaText, text]);

    return (
        <Box>
            <Typography variant="h5" paddingTop="1rem" fontWeight="500">
                Write a Reply
            </Typography>
            <Box pt="0.5rem">
                <RichTextEditor
                    onRawTextChange={setText}
                    onPayloadChange={setMetaText} />
            </Box>
            <Box display="flex" width="100%" pt="1rem">
                <Button variant="contained" disabled={isSubmitDisable} sx={{ marginLeft: "auto", marginRight: 0 }} onClick={submit}>
                    Reply
                </ Button>
            </Box>
        </Box>
    )
}

export default ReplyListSection;