import React, { useCallback, useEffect, useState } from "react";
import RichTextEditor from "../../common/RichTextEditor";
import { Box, Button, CircularProgress, Divider, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { getForumPost, ForumPost as ForumPostType } from "../../api/forum";
import { firebaseTimestampToDate } from "../../Firebase";
import ReplyListSection from "./ReplyListSection";

export default function ForumPost() {
    const nevigate = useNavigate();
    const { forumPostId } = useParams();


    const [forumPost, setForumPost] = useState<ForumPostType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);

    const fetchData = useCallback(async () => {
        if (forumPostId === undefined) {
            return;
        }
        setIsLoading(true);
        try {
            const data = await getForumPost(forumPostId);
            setForumPost(data);
        } catch (error) {
            setIsError(true);
            console.error('Error fetching post data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [setForumPost, forumPostId]);

    const reload = useCallback(() => {
        fetchData();
    }, [fetchData]);
    
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" p="8rem">
                <CircularProgress />
            </Box>
        )
    }

    if (isError) {
        nevigate("/forum");
    }

    return (
        <Box display="flex" justifyContent="center" >
            <Box maxWidth='md' width="100%">
                <Box display="flex" flexDirection="column">

                    <Box px="0.5rem" pt="0.5rem" marginRight="auto" marginLeft="0">
                        <Button color="primary" startIcon={<ArrowBack />} onClick={() => { nevigate("/forum") }}>Back To Forum</Button>
                    </Box>
                    <Typography variant="h4" p="1rem">{forumPost?.post?.title}</Typography>

                    <RichTextEditor
                        initialPayload={forumPost?.post?.content?.raw}
                        isDisplay
                    />
                    <Box p="0.5rem">
                        <Typography variant="subtitle2" align="right" fontWeight="normal">
                            Post By {forumPost?.post?.userProfile?.displayName ?? "Unknown"}
                        </Typography>
                        <Typography variant="subtitle2" align="right" fontWeight="normal">
                            At {forumPost?.post?.createdAt ? firebaseTimestampToDate(forumPost.post.createdAt)
                                .toLocaleString('en-US', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                }) : "Unknown"}
                        </Typography>
                    </Box>
                    <Divider sx={{ padding: '0.5rem' }} />
                    <ReplyListSection replies={forumPost?.reply ?? []} postId={forumPostId ?? ""} reload={reload}/>
                </Box>
            </Box>
        </Box>
    )
}